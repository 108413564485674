<template>
  <Header title="Cottages" />
  <!----- SLIDER SECTION ----->
  <section class="home_slider_container">
    <div class="home_slider_section margin_top">
      <div class="home_slider_row overflow_hidden">
        <div class="home_slider_col">
          <div class="home_slider_block">
            <!-- slick Start -->
            <div class="home_main_slider1">
              <!-- Main Slider Images -->
              <div class="home_main_slider">
                <div class="main_slider_img_block">
                  <img :src="dimond_villa_01" class="main_slider_img" />
                  <!-- content -->
                  <div class="slider_content_block">
                    <div class="inner_banner_block">
                      <p class="body_heading tc">Exclusive Cottages</p>
                    </div>
                    <!-- content end -->
                  </div>
                  <!-- btn end -->
                </div>
                <div class="main_slider_img_block">
                  <img :src="dimond_villa_02" class="main_slider_img" />
                  <!-- content -->
                  <div class="slider_content_block">
                    <div class="inner_banner_block">
                      <p class="body_heading tc">Exclusive Cottages</p>
                    </div>
                    <!-- btn end -->
                  </div>
                  <!-- content End -->
                </div>
                <div class="main_slider_img_block">
                  <img :src="dimond_villa_03" class="main_slider_img" />
                  <!-- content -->
                  <div class="slider_content_block">
                    <div class="inner_banner_block">
                      <p class="body_heading tc">Exclusive Cottages</p>
                    </div>
                    <!-- btn end -->
                  </div>
                  <!-- content End -->
                </div>
              </div>
              <!-- arrow -->
              <!-- <div class="slick-button-next"></div>
                            <div class="slick-button-prev"></div> -->
              <!-- content End -->
            </div>
            <!-- slick End -->
            <!-- thumbs Slider images -->
            <div thumbsSlider="" class="slider_thumb_outter">
              <div class="slider_thumb_inner">
                <div class="slider_thumb_img_slider">
                  <div class="slider_thumb_img_block">
                    <img :src="dimond_villa_01" class="slider_thumb_img" />
                  </div>
                </div>
                <div class="slider_thumb_img_slider">
                  <div class="slider_thumb_img_block">
                    <img :src="dimond_villa_02" class="slider_thumb_img" />
                  </div>
                </div>
                <div class="slider_thumb_img_slider">
                  <div class="slider_thumb_img_block">
                    <img :src="dimond_villa_03" class="slider_thumb_img" />
                  </div>
                </div>
              </div>
            </div>
            <!-- thumbs Slider images end -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ----------- data & Explore the Villa & Amenities ----------- -->
  <section class="villa_data_section row_padding lef_img villa_bg_img">
    <div class="villa_data_row main_container">
      <div class="villa_data_col villa_left_col">
        <div class="villa_left_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading">Vacation Time’s a-calling!</p>
            <div class="small_body_font">
              <p>
                A fun and chill weekend is awaiting you in our luxurious and
                sophisticated cottages. With a view overlooking the tapi river,
                enjoy the lush greenery, and beauty of your surroundings and
                connect with nature to calm your mind.
              </p>
              <p>
                Equipped with all the modern facilities and amenities, our
                exclusive cottages are the perfect solution for weekend getaway
                plans with your loved ones.
              </p>
            </div>
            <!-- <div class="btn_block btn_center_block">
                            <div class="btn_border block_cta">
                                <div class="bird bird--one">
                                    <a href="#" class="site_button">
                                        <div class="site_button_div">
                                            <span class="btn_text">PDF Brochure</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="bird bird--one">
                                    <a href="#" class="site_button">
                                        <div class="site_button_div">
                                            <span class="btn_text">Watch 3D Walk through</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
        <div class="villa_photos_section">
          <div class="villa_photos_row">
            <div class="villa_photos_col">
              <div class="villa_photo_title_block">
                <div class="body_heading_block">
                  <p class="body_heading">Cottages Photos</p>
                </div>
              </div>
              <div class="villa_photo_block">
                <div class="villa_photo_inn">
                  <div class="villa_photos_box">
                    <a
                      :href="villa_01"
                      data-lightbox="models"
                      data-title="home-gallery-1"
                    >
                      <img :src="villa_01" alt="" class="villa_photoss" />
                    </a>
                  </div>
                  <div class="villa_photos_box">
                    <a
                      :href="villa_02"
                      data-lightbox="models"
                      data-title="home-gallery-1"
                    >
                      <img :src="villa_02" alt="" class="villa_photoss" />
                    </a>
                  </div>
                  <div class="villa_photos_box">
                    <a
                      :href="villa_03"
                      data-lightbox="models"
                      data-title="home-gallery-1"
                    >
                      <img :src="villa_03" alt="" class="villa_photoss" />
                    </a>
                  </div>
                  <div class="villa_photos_box">
                    <a
                      :href="villa_04"
                      data-lightbox="models"
                      data-title="home-gallery-1"
                    >
                      <img :src="villa_04" alt="" class="villa_photoss" />
                    </a>
                  </div>
                  <div class="villa_photos_box">
                    <a
                      :href="villa_05"
                      data-lightbox="models"
                      data-title="home-gallery-1"
                    >
                      <img :src="villa_05" alt="" class="villa_photoss" />
                    </a>
                  </div>
                  <div class="villa_photos_box">
                    <a
                      :href="villa_06"
                      data-lightbox="models"
                      data-title="home-gallery-1"
                    >
                      <img :src="villa_06" alt="" class="villa_photoss" />
                    </a>
                  </div>
                  <div class="villa_photos_box">
                    <a
                      :href="villa_07"
                      data-lightbox="models"
                      data-title="home-gallery-1"
                    >
                      <img :src="villa_07" alt="" class="villa_photoss" />
                    </a>
                  </div>
                  <div class="villa_photos_box">
                    <a
                      :href="villa_08"
                      data-lightbox="models"
                      data-title="home-gallery-1"
                    >
                      <img :src="villa_08" alt="" class="villa_photoss" />
                    </a>
                  </div>
                  <div class="villa_photo_btn_out btn_border block_cta big_btn">
                    <router-link to="/photo#cottages" class="site_button">
                      <div class="site_button_div">
                        <span class="font_20">View All</span>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="villa_left_block villa_left_block_02">
          <div class="body_heading_block">
            <p class="body_heading">Explore the Cottages</p>
          </div>
          <div class="villa_features_block">
            <p class="font_20 villa_features_main_heading">Bedrooms</p>
            <div class="villa_features_outter">
              <div class="villa_features_inner">
                <div class="villa_features_inner_box">
                  <div class="villa_features_img">
                    <img :src="bedroom" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title tc">Bedrooms 1</p>
                    <!-- <p class="body_font villa_feature_cate tc">King</p> -->
                  </div>
                </div>
              </div>
              <div class="villa_features_inner">
                <div class="villa_features_inner_box">
                  <div class="villa_features_img">
                    <img :src="safety_locker" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title tc">
                      Safety Locker
                    </p>
                    <!-- <p class="body_font villa_feature_cate tc">King</p> -->
                  </div>
                </div>
              </div>
              <div class="villa_features_inner">
                <div class="villa_features_inner_box">
                  <div class="villa_features_img">
                    <img :src="own_wardrode" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title tc">Own Wardrobe</p>
                    <!-- <p class="body_font villa_feature_cate tc">Floor Mattress</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="villa_features_block">
                        <p class="font_20 villa_features_main_heading">Other spaces</p>
                        <div class="villa_features_outter">
                            <div class="villa_features_inner">
                                <div class="villa_features_inner_box">
                                    <div class="villa_features_img">
                                        <img :src="os_01">
                                    </div>
                                    <div class="villa_features_info">
                                        <p class="body_font villa_feature_title tc">Drawing Room</p>
                                    </div>
                                </div>
                            </div>
                            <div class="villa_features_inner">
                                <div class="villa_features_inner_box">
                                    <div class="villa_features_img">
                                        <img :src="os_02">
                                    </div>
                                    <div class="villa_features_info">
                                        <p class="body_font villa_feature_title tc">Living Room</p>
                                    </div>
                                </div>
                            </div>
                            <div class="villa_features_inner">
                                <div class="villa_features_inner_box">
                                    <div class="villa_features_img">
                                        <img :src="os_03">
                                    </div>
                                    <div class="villa_features_info">
                                        <p class="body_font villa_feature_title tc">Dinning Aera</p>
                                    </div>
                                </div>
                            </div>
                            <div class="villa_features_inner">
                                <div class="villa_features_inner_box">
                                    <div class="villa_features_img">
                                        <img :src="os_04">
                                    </div>
                                    <div class="villa_features_info">
                                        <p class="body_font villa_feature_title tc">Own Wardrode in All bedrooms</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

          <!-- <div class="btn_block btn_center_block">
                        <div class="bird bird--one">
                            <div class="btn_border block_cta">
                                <a href="#" class="site_button">
                                    <div class="site_button_div">
                                        <span class="btn_text">Floor Plan Pdf</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div> -->
        </div>
        <div class="villa_left_block villa_left_block_03 top_border">
          <div class="body_heading_block">
            <p class="body_heading">Amenities</p>
          </div>
          <div class="villa_features_block">
            <p class="font_20 villa_features_main_heading">General</p>
            <div class="villa_features_outter mt-0">
              <div class="villa_features_block top_border">
                <div class="villa_features_outter">
                  <div class="villa_amenities_inner">
                    <div class="villa_amenities_inner_box">
                      <div class="villa_features_img">
                        <img :src="amenities_02" />
                      </div>
                      <div class="villa_features_info">
                        <p class="body_font villa_feature_title">TV</p>
                      </div>
                    </div>
                  </div>
                  <div class="villa_amenities_inner">
                    <div class="villa_amenities_inner_box">
                      <div class="villa_features_img">
                        <img :src="amenities_05" />
                      </div>
                      <div class="villa_features_info">
                        <p class="body_font villa_feature_title">
                          Air-conditioned rooms
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="villa_amenities_inner">
                    <div class="villa_amenities_inner_box">
                      <div class="villa_features_img">
                        <img :src="no_wifi" />
                      </div>
                      <div class="villa_features_info">
                        <p class="body_font villa_feature_title">
                          No Wifi
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="villa_amenities_inner">
                                <div class="villa_amenities_inner_box">
                                    <div class="villa_features_img">
                                        <img :src="ro_water">
                                    </div>
                                    <div class="villa_features_info">
                                        <p class="body_font villa_feature_title">RO Water cooler</p>
                                    </div>
                                </div>
                            </div> -->
              <!-- <div class="villa_features_block top_border">
                                <p class="font_20 villa_features_main_heading">Kitchen</p>
                                <div class="villa_features_outter">
                                    <div class="villa_amenities_inner">
                                        <div class="villa_amenities_inner_box">
                                            <div class="villa_features_img">
                                                <img :src="kitchen_01">
                                            </div>
                                            <div class="villa_features_info">
                                                <p class="body_font villa_feature_title">Wifi</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="villa_amenities_inner">
                                        <div class="villa_amenities_inner_box">
                                            <div class="villa_features_img">
                                                <img :src="Kitchen_Wardrobe">
                                            </div>
                                            <div class="villa_features_info">
                                                <p class="body_font villa_feature_title">Kitchen Wardrobe</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
              <div class="villa_features_block top_border">
                <p class="font_20 villa_features_main_heading">
                  Bedroom & Bathroom
                </p>
                <div class="villa_features_outter">
                  <div class="villa_amenities_inner">
                    <div class="villa_amenities_inner_box">
                      <div class="villa_features_img">
                        <img :src="bb_01" />
                      </div>
                      <div class="villa_features_info">
                        <p class="body_font villa_feature_title">Towels</p>
                      </div>
                    </div>
                  </div>
                  <div class="villa_amenities_inner">
                    <div class="villa_amenities_inner_box">
                      <div class="villa_features_img">
                        <img :src="bb_02" />
                      </div>
                      <div class="villa_features_info">
                        <p class="body_font villa_feature_title">
                          Toilet Paper
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="villa_amenities_inner">
                    <div class="villa_amenities_inner_box">
                      <div class="villa_features_img">
                        <img :src="Toiletries" />
                      </div>
                      <div class="villa_features_info">
                        <p class="body_font villa_feature_title">Toiletries</p>
                      </div>
                    </div>
                  </div>
                  <div class="villa_amenities_inner">
                    <div class="villa_amenities_inner_box">
                      <div class="villa_features_img">
                        <img :src="Jaccuzi" />
                      </div>
                      <div class="villa_features_info">
                        <p class="body_font villa_feature_title">
                          Water Bottle
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="villa_features_block top_border servicesBlock">
                <p class="font_20 villa_features_main_heading">Services</p>
                <div class="villa_features_outter">
                  <div class="villa_amenities_inner">
                    <div class="villa_amenities_inner_box">
                      <div class="villa_features_img">
                        <img :src="Housekeeping" />
                      </div>
                      <div class="villa_features_info">
                        <p class="body_font villa_feature_title">
                          House Keeping
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="less_btn_block">
                        <a href="#" class="less_btn">
                            <span class="btn_text font_20">less</span>
                        </a>
                    </div> -->
        </div>
      </div>
      <div class="villa_data_col villa_right_col" id="wrapper_box">
        <div class="villa_right_block" id="sticky_box">
          <div class="villa_right_outter">
            <div class="price_box">
              <div class="price_rate_box">
                <p class="new_price font_24 wt">Book Your Luxury Living!</p>
                <p class="total_rate box_title_text wt font_24">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  4.9
                </p>
              </div>
            </div>
            <div class="separater"></div>
            <!-- <Datepicker
              type="date"
              :min="new Date().toISOString().substr(0, 10)"
              @closed="dateChange()"
              v-model="form.rangeDate"
              placeholder="Check In Check Out"
              format="dd-MM-yyyy"
              value-format="dd-MM-yyyy"
              :min-date="new Date()"
              modelAuto
              range
              multiCalendars
              multiCalendarsSolo
            />
            <div
              class="invalid-feedback fw-bold fst-italic text-danger mt-1"
              :style="{ display: checkInDateError }"
            >
              Please enter checkin checkout Date.
            </div>
            <div class="guest_box box_border">
              
              <div class="flex items-center justify-center gap-x-4">
                <div class="dd_inner" style="align-items: center !important">
                  <div class="d-flex">
                    <p class="body_font">Adults</p>
                  </div>
                  <button
                    class="px-4 py-2 text-white bg-red-600 focus:outline-none increment"
                    @click="decrement"
                  >
                    -
                  </button>
                  {{ form.total_adult }}
                  <button
                    class="px-4 py-2 text-white bg-blue-600 focus:outline-none increment"
                    @click="increment"
                  >
                    +
                  </button>
                </div>
                <div
                  class="dd_inner"
                  style="
                    align-items: center !important;
                    margin-top: 5px !important;
                  "
                >
                  <div class="d-flex">
                    <p class="body_font">Childs</p>
                  </div>
                  <button
                    class="px-4 py-2 text-white bg-red-600 focus:outline-none increment"
                    @click="decrementChild"
                  >
                    -
                  </button>
                  {{ form.total_child }}
                  <button
                    class="px-4 py-2 text-white bg-blue-600 focus:outline-none increment"
                    @click="incrementChild"
                  >
                    +
                  </button>
                </div>
              </div>
              <div
                class="invalid-feedback fw-bold fst-italic text-danger mt-1"
                :style="{ display: totalAdultError }"
              >
                Please enter Total Adult.
              </div>

            </div>
            <div class="guest_box box_border">
              <div class="flex items-center justify-center gap-x-4">
                <div class="dd_inner" style="align-items: center !important">
                  <div class="d-flex">
                    <p class="body_font">Villa Quantity</p>
                  </div>
                  <button
                    class="px-4 py-2 text-white bg-red-600 focus:outline-none increment"
                    @click="decrementQTY"
                  >
                    -
                  </button>
                  {{ form.qty }}
                  <button
                    class="px-4 py-2 text-white bg-blue-600 focus:outline-none increment"
                    @click="incrementQTY"
                  >
                    +
                  </button>
                </div>
              </div>
              <div
                class="invalid-feedback fw-bold fst-italic text-danger mt-1"
                :style="{ display: totalQTYError }"
              >
                Please enter No of villa.
              </div>
            </div>
            <div
              class="guest_box box_border"
              style="border: none !important"
              v-show="loader"
            >
              <div
                class="flex items-center justify-center gap-x-4"
                style="text-align: center"
              >
                <span style="margin-top: 10px">--- LOADING ---</span>
              </div>
            </div>
            <div class="price_cal_outter">
              <div class="price_cal_inner">
               

                <div class="price_cal_box">
                  <p class="price_cal_title font_20">Villa Price</p>
                  <p class="price_cal_num font_20">
                    ₹{{ villa_price ? villa_price : 0 }}
                  </p>
                </div>
                <div class="price_cal_box">
                  <p class="price_cal_title font_20">Extra Person Charge</p>
                  <p class="price_cal_num font_20" v-if="bookingResult">
                    ₹{{
                      bookingResult.extra_person_charge
                        ? bookingResult.extra_person_charge
                        : 0
                    }}
                  </p>
                  <p class="price_cal_num font_20" v-else>₹0</p>
                </div>
                <div class="price_cal_box">
                  <p class="price_cal_title font_20">GST</p>
                  <p class="price_cal_num font_20" v-if="bookingResult">
                    ₹{{ bookingResult.gst_tax ? bookingResult.gst_tax : 0 }}
                  </p>
                  <p class="price_cal_num font_20" v-else>₹0</p>
                </div>
              </div>
            </div>
            <div class="separater"></div>
            <div class="total_price_cal_box price_cal_box">
              <p class="total_price_cal_title box_title_text wt">Total Price</p>
              <p
                class="total_price_cal_num box_title_text wt price_cal_num"
                v-if="bookingResult"
              >
                ₹{{ bookingResult.total ? bookingResult.total : 0 }}
              </p>
              <p class="price_cal_num font_20" v-else>₹0</p>
            </div> -->
            <div class="btn_block btn_center_block">
              <div class="btn_border block_cta big_btn">
                <a href="javascript:void(0)" @click="book" class="site_button">
                  <div class="site_button_div">
                    <span class="font_20">Book Now</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="right_outter_02">
            <div class="right_inner_02">
              <div class="right_inner_block">
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.35082 5.41C0.35082 3.95 0.77082 2.81 1.61082 1.99C2.47082 1.15 3.57082 0.729998 4.91082 0.729998C6.25082 0.729998 7.34082 1.15 8.18082 1.99C9.04082 2.81 9.47082 3.95 9.47082 5.41C9.47082 6.87 9.04082 8.02 8.18082 8.86C7.34082 9.7 6.25082 10.12 4.91082 10.12C3.57082 10.12 2.47082 9.7 1.61082 8.86C0.77082 8.02 0.35082 6.87 0.35082 5.41ZM18.6508 1.12L6.68082 22H3.32082L15.2908 1.12H18.6508ZM4.91082 2.8C3.65082 2.8 3.02082 3.67 3.02082 5.41C3.02082 7.17 3.65082 8.05 4.91082 8.05C5.51082 8.05 5.97082 7.84 6.29082 7.42C6.63082 6.98 6.80082 6.31 6.80082 5.41C6.80082 3.67 6.17082 2.8 4.91082 2.8ZM12.5608 17.68C12.5608 16.22 12.9808 15.08 13.8208 14.26C14.6808 13.42 15.7808 13 17.1208 13C18.4408 13 19.5208 13.42 20.3608 14.26C21.2208 15.08 21.6508 16.22 21.6508 17.68C21.6508 19.14 21.2208 20.29 20.3608 21.13C19.5208 21.97 18.4408 22.39 17.1208 22.39C15.7808 22.39 14.6808 21.97 13.8208 21.13C12.9808 20.29 12.5608 19.14 12.5608 17.68ZM17.0908 15.07C15.8308 15.07 15.2008 15.94 15.2008 17.68C15.2008 19.42 15.8308 20.29 17.0908 20.29C18.3508 20.29 18.9808 19.42 18.9808 17.68C18.9808 15.94 18.3508 15.07 17.0908 15.07Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="right_inner_block">
                <p class="box_title_text">EARLY BIRD</p>
                <p class="btn_text">
                  Use Code: EARLYBIRD | Book and get 15% off upto ₹20,000.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- Villa Highlights ---------- -->
  <section
    class="villa_highlights_section highlights_section about_us_vector row_padding"
  >
    <div class="highlights_row main_container">
      <div class="highlights_col">
        <div class="body_heading_block">
          <p class="body_heading">Villa Highlights</p>
        </div>
        <div class="highlights_block">
          <div class="tab">
            <button
              class="tablinks active"
              @click="openCity($event, 'highlights01')"
            >
              Exterior
            </button>
            <button class="tablinks" @click="openCity($event, 'highlights02')">
              Bed Room
            </button>
            <button class="tablinks" @click="openCity($event, 'highlights03')">
              Bathroom
            </button>
            <button class="tablinks" @click="openCity($event, 'highlights04')">
              Lawn
            </button>
          </div>

          <div id="highlights01" class="tabcontent main_active">
            <div class="tab_content_outter">
              <div class="tab_content_inner">
                <div class="tab_content_img_block">
                  <img :src="highlights_img_1" class="tab_content_img" />
                </div>
                <div class="tab_content_block">
                  <p class="small_body_font">
                    With a view overlooking the tapi river, you can enjoy the
                    peace, calm, and serenity of the place and reconnect with
                    yourself. Our villas are designed in a way to give you
                    maximum privacy and space for you to enjoy with your loved
                    ones.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="highlights02" class="tabcontent">
            <div class="tab_content_outter">
              <div class="tab_content_inner">
                <div class="tab_content_img_block">
                  <img :src="highlights_img_2" class="tab_content_img" />
                </div>
                <div class="tab_content_block">
                  <p class="small_body_font">
                    A place of personal retreat - where you can rest and
                    recharge. A fully-furnished bedroom with a well-constructed
                    mattress will turn your “so-so” rest into a refreshing
                    retreat on your weekend getaway. The lighting of the room is
                    designed to make you feel cherry and bloomy. The warm color
                    palette of the room creates a tranquil and calming aura and
                    creates an atmosphere that you desire.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="highlights03" class="tabcontent">
            <div class="tab_content_outter">
              <div class="tab_content_inner">
                <div class="tab_content_img_block">
                  <img :src="highlights_img_3" class="tab_content_img" />
                </div>
                <div class="tab_content_block">
                  <p class="small_body_font">
                    Hop onto a relaxing and wonderfully comforting experience.
                    Feel the luxury and the ultimate sophistication with our
                    premium services and amenities for you to have a relaxed and
                    fun getaway with your loved ones.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="highlights04" class="tabcontent">
            <div class="tab_content_outter">
              <div class="tab_content_inner">
                <div class="tab_content_img_block">
                  <img :src="highlights_img_4" class="tab_content_img" />
                </div>
                <div class="tab_content_block">
                  <p class="small_body_font">
                    Connect with nature and rejuvenate in the garden with your
                    loved one. Away from the hustle-bustle of everyday life,
                    regain your sanity, peace, calmness, and tranquility amidst
                    lush greenery and a view overlooking the tapi river.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- Villa Rules & Activities Near Villa ---------- -->
  <section
    class="villa_rules_section row_padding pdb_0 long_l_leaf long_r_leaf"
  >
    <div class="villa_rules_row main_container width_50">
      <div class="villa_rules_col">
        <div class="villa_rules_block">
          <div class="body_heading_block desk_heading">
            <p class="body_heading">Villa Rules</p>
            <div class="points_blocks">
              <div class="small_body_font small_allow plan_dream_box">
                <p class="font_20 villa_features_main_heading">Allowed</p>
                <ul>
                  <li>Smoking (Outside of villa)</li>
                  <li>Pets</li>
                  <li>Events/Parties</li>
                </ul>
              </div>
              <div class="small_body_font small_not_allow plan_dream_box">
                <p class="font_20 villa_features_main_heading">Not Allowed</p>
                <ul>
                  <li>Smoking(inside villa)</li>
                  <li>Loud Music</li>
                  <li>Visitors</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="villa_left_block villa_left_block_03 top_border">
          <div class="body_heading_block">
            <p class="body_heading">Explore exciting activities near Villa</p>
            <p class="alert_notes">Extra charges applicable*</p>
          </div>
          <div class="villa_features_block">
            <!-- <p class="font_20 villa_features_main_heading">General</p> -->
            <div class="villa_features_outter">
              <div class="villa_amenities_inner">
                <div class="villa_amenities_inner_box">
                  <div class="villa_features_img">
                    <img :src="activities_01" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title">Club rio</p>
                  </div>
                </div>
              </div>
              <div class="villa_amenities_inner">
                <div class="villa_amenities_inner_box">
                  <div class="villa_features_img">
                    <img :src="activities_02" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title">
                      Children play area
                    </p>
                  </div>
                </div>
              </div>
              <div class="villa_amenities_inner">
                <div class="villa_amenities_inner_box">
                  <div class="villa_features_img">
                    <img :src="activities_03" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title">
                      Indoor / Outdoor games / Swimming pool
                    </p>
                  </div>
                </div>
              </div>
              <div class="villa_amenities_inner">
                <div class="villa_amenities_inner_box">
                  <div class="villa_features_img">
                    <img :src="activities_04" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title">
                      Resort/water park
                    </p>
                  </div>
                </div>
              </div>
              <div class="villa_amenities_inner">
                <div class="villa_amenities_inner_box">
                  <div class="villa_features_img">
                    <img :src="activities_05" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title">Cinema</p>
                  </div>
                </div>
              </div>
              <div class="villa_amenities_inner">
                <div class="villa_amenities_inner_box">
                  <div class="villa_features_img">
                    <img :src="activities_06" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title">Cycling</p>
                  </div>
                </div>
              </div>
              <div class="villa_amenities_inner">
                <div class="villa_amenities_inner_box">
                  <div class="villa_features_img">
                    <img :src="activities_07" />
                  </div>
                  <div class="villa_features_info">
                    <p class="body_font villa_feature_title">DiscoTheque</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- FILLER SECTION ---------- -->
  <Download />
  <!-- ---------- REVIEW FILLER SECTION ---------- -->
  <!-- <section class="review_section row_padding review_vector_section">
        <div class="review_row main_container">
            <div class="review_col">
                <div class="review_block">
                    <p class="body_heading tc">Reviews and Rating</p> 
                    <div class="feed_block">
                        <img :src="feedback">
                        <p class="font_20">Write a review</p>
                    </div>
                </div>
            </div>
            <div class="right_vector_outter_box">
                <div class="right_svg_move">
                    <svg width="454" height="51" viewBox="0 0 454 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M453.262 32.5001C453.262 32.5001 355.762 -5.00003 274.762 28C193.762 61.0001 149.501 58.5 9.50098 9.5" stroke="black" stroke-dasharray="2 2"/>
                        <path d="M14.5234 1.01465L1.04282 6.64648L7.57913 20.5322" stroke="black" stroke-dasharray="2 2"/>
                    </svg>
                </div>
                <img :src="right_single_title" alt="" class="right_vector_animation">
            </div>
        </div>
    </section> -->
  <Footer />
  <Login :popupMobile="popupMobile" :queryData="queryData" @update="onUpdate($event)" />
</template>

<script>
import Footer from "../../components/Footer.vue";
import Header from "../../components/Header.vue";
import Login from "../../components/LoginPopup.vue";
import dimond_villa_01 from "../../assets/images/cotages_inenr_baner_1.jpg";
import dimond_villa_02 from "../../assets/images/cotages_inenr_baner_2.jpg";
import dimond_villa_03 from "../../assets/images/cotages_inenr_baner_3.jpg";
import bedroom from "../../assets/images/inner_page/bedroom.png";
import os_01 from "../../assets/images/inner_page/os_01.png";
import os_02 from "../../assets/images/inner_page/os_02.png";
import os_03 from "../../assets/images/inner_page/os_03.png";
import os_04 from "../../assets/images/inner_page/os_04.png";
import own_wardrode from "../../assets/images/inner_page/own_wardrode.png";
import safety_locker from "../../assets/images/inner_page/safety_locker.png";
import amenities_01 from "../../assets/images/inner_page/amenities_01.png";
import amenities_02 from "../../assets/images/inner_page/amenities_02.png";
import amenities_03 from "../../assets/images/inner_page/amenities_03.png";
import amenities_04 from "../../assets/images/inner_page/amenities_04.png";
import amenities_05 from "../../assets/images/inner_page/amenities_05.png";
import amenities_06 from "../../assets/images/inner_page/amenities_06.png";
import amenities_07 from "../../assets/images/inner_page/amenities_07.png";
import kitchen_01 from "../../assets/images/inner_page/kitchen_01.png";
import kitchen_02 from "../../assets/images/inner_page/kitchen_02.png";
import kitchen_03 from "../../assets/images/inner_page/kitchen_03.png";
import kitchen_04 from "../../assets/images/inner_page/kitchen_04.png";
import kitchen_05 from "../../assets/images/inner_page/kitchen_05.png";
import kitchen_06 from "../../assets/images/inner_page/kitchen_06.png";
import Kitchen_Wardrobe from "../../assets/images/inner_page/Kitchen-Wardrobe.png";
import Housekeeping from "../../assets/images/inner_page/Flexible Housekeeping.png";
import bb_01 from "../../assets/images/inner_page/bb_01.png";
import bb_02 from "../../assets/images/inner_page/bb_02.png";
import highlights_img_1 from "../../assets/images/new_images/c-exterior.jpg";
import highlights_img_2 from "../../assets/images/new_images/c-bedroom.jpg";
import highlights_img_3 from "../../assets/images/new_images/c-bathroom.jpg";
import highlights_img_4 from "../../assets/images/new_images/c-lawn.jpg";
import activities_01 from "../../assets/images/inner_page/activities_01.png";
import activities_02 from "../../assets/images/inner_page/activities_02.png";
import activities_03 from "../../assets/images/inner_page/activities_03.png";
import activities_04 from "../../assets/images/inner_page/activities_04.png";
import activities_05 from "../../assets/images/inner_page/activities_05.png";
import activities_06 from "../../assets/images/inner_page/activitie_06.png";
import activities_07 from "../../assets/images/inner_page/discotheque.png";
import filler_img from "../../assets/images/filler_img.png";
// import GP_btn_01 from '../../assets/images/GP_btn_01.png';
import GP_btn_01 from "../../assets/images/gp-img.png";
import AS_btn_01Png from "../../assets/images/as-img.png";
import feedback from "../../assets/images/inner_page/feedback.png";
import right_single_title from "../../assets/images/right_single_title.png";
import villa_01 from "../../assets/images/inner_page/cottages/C1.jpg";
import villa_02 from "../../assets/images/inner_page/cottages/C2.jpg";
import villa_03 from "../../assets/images/inner_page/cottages/C3.jpg";
import villa_04 from "../../assets/images/inner_page/cottages/C4.jpg";
import villa_05 from "../../assets/images/inner_page/cottages/C5.jpg";
import villa_06 from "../../assets/images/inner_page/cottages/C6.jpg";
import villa_07 from "../../assets/images/inner_page/cottages/C7.jpg";
import villa_08 from "../../assets/images/inner_page/cottages/C8.jpg";
import ro_water from "../../assets/images/inner_page/RO-Water-cooler.png";
import no_wifi from "../../assets/images/inner_page/no_wifi.png";
import Toiletries from "../../assets/images/inner_page/Toiletries.png";
import Jaccuzi from "../../assets/images/inner_page/Water Bottle.png";
import Form from "vform";
import { ref } from "vue";
// import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { isProxy, toRaw } from "vue";

import Download from "@/pages/Download.vue";

export default {
  name: "Cottage",
  components: { Header, Footer, Login, Download },
  data() {
    return {
      dimond_villa_01: dimond_villa_01,
      dimond_villa_02: dimond_villa_02,
      dimond_villa_03: dimond_villa_03,
      bedroom: bedroom,
      os_01: os_01,
      os_02: os_02,
      os_03: os_03,
      os_04: os_04,
      own_wardrode: own_wardrode,
      safety_locker: safety_locker,
      amenities_01: amenities_01,
      amenities_02: amenities_02,
      amenities_03: amenities_03,
      amenities_04: amenities_04,
      amenities_05: amenities_05,
      amenities_06: amenities_06,
      amenities_07: amenities_07,
      kitchen_01: kitchen_01,
      kitchen_02: kitchen_02,
      kitchen_03: kitchen_03,
      kitchen_04: kitchen_04,
      kitchen_05: kitchen_05,
      kitchen_06: kitchen_06,
      Housekeeping: Housekeeping,
      bb_01: bb_01,
      bb_02: bb_02,
      highlights_img_1: highlights_img_1,
      highlights_img_2: highlights_img_2,
      highlights_img_3: highlights_img_3,
      highlights_img_4: highlights_img_4,
      activities_01: activities_01,
      activities_02: activities_02,
      activities_03: activities_03,
      activities_04: activities_04,
      activities_05: activities_05,
      activities_06: activities_06,
      activities_07: activities_07,
      filler_img: filler_img,
      GP_btn_01: GP_btn_01,
      AS_btn_01Png: AS_btn_01Png,
      feedback: feedback,
      right_single_title: right_single_title,
      villa_01: villa_01,
      villa_02: villa_02,
      villa_03: villa_03,
      villa_04: villa_04,
      villa_05: villa_05,
      villa_06: villa_06,
      villa_07: villa_07,
      villa_08: villa_08,
      ro_water: ro_water,
      no_wifi: no_wifi,
      Kitchen_Wardrobe: Kitchen_Wardrobe,
      Toiletries: Toiletries,
      Jaccuzi: Jaccuzi,
      form: new Form({
        id: 3, // Live ID
        // id: 6, // Local ID
        rangeDate: ref(),
        check_in_date: "",
        check_out_date: "",
        total_adult: 0,
        total_child: 0,
        qty: 0,
        booking: {
          total: 0,
          gst_tax: 0,
          extra_person_charge: 0,
          festival_charge: 0,
          gst_tax_percent: 0,
        },
      }),
      token: localStorage.getItem("auth_cus_token"),
      checkInDateError: "none",
      totalAdultError: "none",
      totalQTYError: "none",
      popupMobile: false,
      queryData: "",
      bookingResult: {
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
      },
      loader: false,
      villa_price: 0,
    };
  },
  mounted() {
    let recaptchaScript2 = document.createElement("script");
    recaptchaScript2.setAttribute("src", "./js/slick/slick.js");
    document.head.appendChild(recaptchaScript2);
    let recaptchaScript3 = document.createElement("script");
    recaptchaScript3.setAttribute("src", "./js/slick/slick.min.js");
    document.head.appendChild(recaptchaScript3);
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "./js/script.js");
    document.head.appendChild(recaptchaScript);

    window.scrollTo({ top: 0, behavior: "smooth" });

    this.getStoreValue();
  },
  methods: {
    onUpdate(updatedData) {
      this.popupMobile = updatedData;
    },
    async getBookingResults() {
      this.loader = true;
      await axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/villa-booking-total",
          {
            villa_booking: [
              {
                villa_type_id: this.form.id,
                quantity: this.form.qty,
                person: this.form.total_adult,
                check_in_date: this.form.check_in_date,
                check_out_date: this.form.check_out_date,
              },
            ],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.loader = false;
          if (response.data.code == 200) {
            this.bookingResult = response.data.data;
            this.villa_price = this.bookingResult.total;
            // this.bookingResult.gst_tax_percent = this.bookingResult.gst_tax ? this.bookingResult.gst_tax : 0;
            // this.bookingResult.gst_tax = (this.bookingResult.total * this.bookingResult.gst_tax) / 100;
            if (this.bookingResult) {
              this.bookingResult.gst_tax_percent = this.bookingResult.gst_tax
                ? this.bookingResult.gst_tax
                : 0;
              this.bookingResult.gst_tax =
                (this.bookingResult.total * this.bookingResult.gst_tax) / 100;
              this.bookingResult.total =
                parseInt(this.bookingResult.total) +
                parseInt(this.bookingResult.extra_person_charge) +
                parseInt(this.bookingResult.festival_charge) +
                parseInt(this.bookingResult.gst_tax);
            }
          } else {
            this.$toast.error(response.data?.message);
          }
        });
    },
    checkAuth() {
      // if (!this.token) {
      //     this.popupMobile = !this.popupMobile;
      //     this.form.id= null;
      //     this.form.rangeDate= ref();
      //     this.form.check_in_date= "";
      //     this.form.check_out_date= "";
      //     this.form.total_adult= 0;
      //     this.form.total_child= 0;
      //     return 0;
      // } else {
      if (
        this.form.id > 0 &&
        this.form.check_in_date.length > 0 &&
        this.form.check_out_date.length > 0 &&
        this.form.total_adult > 0 &&
        this.form.qty > 0
      ) {
        this.getBookingResults();
      }
      return 1;
      // }
    },
    openCity(evt, cityName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    },
    dateChange() {
      // var resultAuth = this.checkAuth();
      // if(resultAuth) {
      this.dateResult();
      if (this.form.rangeDate.length > 0) {
        this.checkInDateError = "none";
      }
      this.checkAuth();
      // }
    },
    dateResult() {
      const date = this.form.rangeDate;
      if (date.length > 0 && date.length != undefined) {
        date.map(() => {
          this.form.check_in_date = moment(date[0]).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date[1]).format("YYYY-MM-DD");
        });
      } else {
        if (typeof date != undefined) {
          this.form.check_in_date = moment(date).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date).format("YYYY-MM-DD");
        }
      }
    },
    increment() {
      this.form.total_adult++;
      this.checkAuth();
    },
    decrement() {
      if (this.form.total_adult > 0) {
        this.form.total_adult--;
      }
      this.checkAuth();
    },
    incrementChild() {
      this.form.total_child++;
      this.checkAuth();
    },
    decrementChild() {
      if (this.form.total_child > 0) {
        this.form.total_child--;
      }
      this.checkAuth();
    },
    incrementQTY() {
      this.form.qty++;
      this.checkAuth();
    },
    decrementQTY() {
      if (this.form.qty > 0) {
        this.form.qty--;
      }
      this.checkAuth();
    },
    ...mapActions(["addCottage"]),
    book() {
      if (!this.token) {

        this.queryData = { 
          name: "dashboard", 
          params: { 
            bookingtype: "VillaBooking" 
          } 
        }

        this.popupMobile = !this.popupMobile;
        //   console.log("token: ", this.token);
        //   localStorage.setItem("id", this.form.id);
        //   localStorage.setItem("check_in_date", this.form.check_in_date);
        //   localStorage.setItem("check_out_date", this.form.check_out_date);
        //   localStorage.setItem("total_adult", this.form.total_adult);
        //   localStorage.setItem("total_child", this.form.total_child);
        //   localStorage.setItem("qty", this.form.qty);
        //   this.getBookingResults();
        //   localStorage.setItem("gst_tax", this.bookingResult.gst_tax);
        //   localStorage.setItem("total", this.bookingResult.total);
        //   localStorage.setItem(
        //     "extra_person_charge",
        //     this.bookingResult.extra_person_charge
        //   );
        //   localStorage.setItem(
        //     "festival_charge",
        //     this.bookingResult.festival_charge
        //   );
        //   localStorage.setItem(
        //     "gst_tax_percent",
        //     this.bookingResult.gst_tax_percent
        //   );

        //   if (
        //     this.form.check_in_date.length === 0 ||
        //     this.form.check_out_date.length === 0
        //   ) {
        //     this.checkInDateError = "block";
        //   } else if (this.form.total_adult == 0) {
        //     this.totalAdultError = "block";
        //   } else if (this.form.qty == 0) {
        //     this.totalQTYError = "block";
        //   } else {
        //     this.popupMobile = !this.popupMobile;
        //   }
        // } else if (
        //   this.form.check_in_date.length === 0 ||
        //   this.form.check_out_date.length === 0
        // ) {
        //   this.checkInDateError = "block";
        // } else if (this.form.total_adult == 0) {
        //   this.totalAdultError = "block";
        // } else if (this.form.qty == 0) {
        //   this.totalQTYError = "block";
        // } else {
        //   this.dateResult();
        //   this.form.booking.total = this.bookingResult.total;
        //   this.form.booking.gst_tax = this.bookingResult.gst_tax;
        //   this.form.booking.extra_person_charge =
        //     this.bookingResult.extra_person_charge;
        //   this.form.booking.festival_charge = this.bookingResult.festival_charge;
        //   this.form.booking.gst_tax_percent = this.bookingResult.gst_tax_percent;

        //   this.addCottage(this.form);
        //   let rawData = this.allCottages;
        //   if (isProxy(this.allCottages)) {
        //     rawData = toRaw(this.allCottages);
        //   }
        //   if (rawData[0].id) {
        //     this.$router.push({ name: "dashboard" });
        //   }
      } else {
        this.$router.push({ name: "dashboard", params: { bookingtype: "VillaBooking" } });
      }
    },
    getStoreValue() {
      if (this.allCottages) {
        let rawData = this.allCottages;
        if (isProxy(this.allCottages)) {
          rawData = toRaw(this.allCottages);
        }
        if (rawData[0] && rawData[0].id == 2) {
          this.form.id = rawData[0].id;
          this.form.qty = rawData[0].qty;
          this.form.check_in_date = rawData[0].check_in_date;
          this.form.check_out_date = rawData[0].check_out_date;
          this.form.total_child = rawData[0].total_child;
          this.form.total_adult = rawData[0].total_adult;
          this.form.bookingResult.total = rawData[0].booking.total;
          this.form.bookingResult.gst_tax = rawData[0].booking.gst_tax;
          this.form.bookingResult.extra_person_charge =
            rawData[0].booking.extra_person_charge;
          this.form.bookingResult.festival_charge =
            rawData[0].booking.festival_charge;
          this.form.bookingResult.gst_tax_percent =
            rawData[0].booking.gst_tax_percent;
        }
      }
    },
  },
  computed: mapGetters(["allCottages"]),
};
</script>

<style>
.mt-0 {
  margin-top: 0;
}
.alert_notes {
  color: red;
}
/* .dp__input_icons {
    height: 28px;
    width: 28px;
    color: #fff;
    margin-top: 10px;
    height: 55px !important;
}

.dp__input {
    background: #7da741;
    border: 2px solid #fff;
    border-radius: 10px;
    padding: 10px 20px;
    margin-top: 20px;
}

.dp__input::placeholder {
    color: #fff;
    font-size: 20px !important;
    margin: auto 0 auto 25px !important;
    opacity: 1 !important;
    padding-left: 50px !important;
} */

.dp__input_icons {
  height: 28px;
  width: 28px;
  color: #fff;
  margin-top: 0px;
  height: 42px !important;
}

.dp__input {
  background: #7da741;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px 20px 10px 45px;
}

.dp__input::placeholder {
  color: #fff;
  font-size: 20px !important;
  margin: auto 0 auto 25px !important;
  opacity: 1 !important;
  padding-left: 50px !important;
}

.dp__input_wrap svg path {
  fill: black !important;
  fill: var(--dp-icon-color) !important;
}

.villa_right_block .booking_form_block .dp__input_wrap svg {
  width: 20px;
  height: 20px !important;
  position: absolute !important;
  top: 5px;
}

.villa_right_block .dp__input_wrap {
  background: transparent !important;
}

.villa_right_block svg.dp__icon.dp__input_icon.dp__input_icons path {
  fill: white !important;
}

/* .dp__main.dp__theme_light { */
/* width: 100%;
    background: transparent !important;
    border: 2px solid white; */
/* } */

.villa_right_block .dp__main.dp__theme_light input {
  background: transparent !important;
}

/* .villa_right_outter  .dp__input_wrap svg {
  
} */
.dp__main.dp__theme_light {
  width: 100%;
  background: transparent !important;
  display: block;
  padding: 0px !important;
}

.villa_right_block .dp__main.dp__theme_light input {
  background: transparent !important;
  margin-top: 0px !important;
  background: #7da741 !important;
  border: 2px solid white !important;
}

.servicesBlock {
  width: 100%;
}

.increment {
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 4px;
  padding: 3px;
  border: 1px solid #fff;
  text-align: center;
  margin: auto;
  cursor: pointer;
  color: #fff;
}

.btn_border.block_cta.big_btn {
  display: unset;
}


@media all and (min-width: 320px) and (max-width: 767px) {
  .dp__flex_display {
  }

  .dp__instance_calendar {
    /* flex: 1 !important; */
    /* width: 50%; */
  }

  .dp__cell_inner {
    width: 24px !important;
    height: 20px !important;
    font-size: 11px;
  }

  .dp__action_row {
    margin-top: 0px;
  }

  .dp__calendar_header_item {
    width: 24px !important;
    height: 20px;
    font-size: 11px;
  }

  .dp__flex_display {
    flex-direction: initial !important;
  }

  .villa_photos_box a {
    width: 100%;
  }

  .villa_photoss {
    width: 100%;
    object-fit: cover;
  }

  .villa_photos_box {
    width: 50%;
  }

  .villa_photo_block {
    margin-bottom: 20px;
  }

  .villa_features_inner {
    width: 100%;
  }

  .villa_features_block {
    margin-bottom: 30px;
  }

  section.villa_data_section.row_padding.lef_img.villa_bg_img {
    background-image: unset;
  }

  p.alert_notes {
    text-align: center;
    display: block;
    padding-bottom: 20px;
  }
}
</style>
